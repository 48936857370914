export function eventcodesName(abrr) {
  if (abrr === 'DS_ON') {
    return  'On Duty';
  }
  if (abrr === 'DS_D') {
     return 'Driving';
  }
  if (abrr === 'DS_OFF') {
    return  'Off Duty';
  }
  if (abrr === 'DR_IND_PC') {
    return  'Personal Use';
  }
  if (abrr === 'LOG_NORMAL_PRECISION') {
    return  'Intermediate w/ CLP';
  }
  if (abrr === 'ENG_UP_NORMAL') {
    return  'Engine Power-up w/ CLP';
  }
  if (abrr === 'ENG_DOWN_NORMAL') {
    return  'Engine Shut-down w/ CLP';
  }

  if (abrr === 'DS_SB') {
    return  'Sleeper';
  }
  if (abrr === 'DR_LOGOUT') {
    return  'Logout';
  }
  if (abrr === 'DR_LOGIN') {
    return  'Login';
  }
  if (abrr === 'DIAG_CLEARED') {
    return  'Engine synchronization data diagnostic (cleared) ';
  }
  if (abrr === 'DIAG_LOGGED') {
    return  'Engine synchronization data diagnostic	';
  }


  if (abrr === 'DR_IND_YM') {
    return  'Yard Moves';
  }
  if (abrr.substring(0, 7) === 'DR_CERT') {
    return  'Certification';
  }
  else{
    return 'remove'
  }
}
