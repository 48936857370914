
// Define an async function
//teste
async function sendChanges(hosEvent, hosEventElement, method) {
  

  const url = `https://backend.apexhos.com/hos_events/${hosEvent}?%24client%5BignoreRev%5D=true&%24client%5BcreateIfNotExist%5D=true&%24client%5BuseServerAuditTime%5D=true`;
  console.log(url);
  console.log(hosEventElement);
const headers = new Headers({
'Host': 'kindzadza2.apexhos.com',
'sec-ch-ua': '"Chromium";v="110", "Not A(Brand";v="24", "Google Chrome";v="110"',
'accept': 'application/json',
'content-type': 'application/json',
'sec-ch-ua-mobile': '?0',
'authorization': localStorage.getItem('token'),
'user-agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/110.0.0.0 Safari/537.36',
'sec-ch-ua-platform': '"Windows"',
'origin': 'https://portal.undercontroltechnology.com',
'sec-fetch-site': 'cross-site',
'sec-fetch-mode': 'cors',
'sec-fetch-dest': 'empty',
'referer': 'https://portal.undercontroltechnology.com/',
'accept-language': 'pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7'
});

const objctbody = JSON.stringify(hosEventElement);

const options = {
method: method,
headers,
body: objctbody
};

fetch(url, options)
.then(response => response.json())
.then(data => console.log(data))
.catch(error => console.error(error));

}

// Export the async function
export default sendChanges;