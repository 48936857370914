export async function getDriverName(token, driverId) {
  // Define the API endpoint URL
  const url = 'https://backend.apexhos.com/users';
  try {
    // Send a fetch request to the API endpoint with the authorization token in the header
    const response = await fetch(url, {
      headers: {
        'authorization': token
      }
    });
    // Parse the response data as JSON
    const data = await response.json();
    // Find the driver object in the response data that matches the given driver ID
    const driver = data.data.find(d => d._id === driverId);
    // If no matching driver is found, throw an error
    if (!driver) {
      throw new Error(`Driver with id ${driverId} not found`);
    }
    // If a matching driver is found, construct the driver name from the first and last name properties
    const driverName = `${driver.firstName} ${driver.lastName}`;
    // Store the driver's company ID in localStorage
    localStorage.setItem('companyId', driver.companyId);
    // Set the driver name as the inner HTML of an element with the ID "driverName"
    document.getElementById('driverName').innerHTML = driverName;
    localStorage.setItem('driverName', driverName);
    // Return the driver name
    return driverName;
  } catch (error) {
    // If an error occurs, log it to the console and throw a new error to indicate that the function failed
    console.error(error);
    throw new Error('Failed to get driver name');
  }
}