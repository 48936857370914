export function companyName(token, companyId) {
const extraData = ['VI', 'GAK'];
const url = `https://backend.apexhos.com/companies/${companyId}?%24client%5BextraData%5D%5B0%5D=${extraData[0]}&%24client%5BextraData%5D%5B1%5D=${extraData[1]}`;
  return fetch(url, {
    headers: {
      'authorization': token
    }
  })
  .then(response => response.json())
  .then(data => {
    const companyNamed = data.name;
    document.getElementById('companyName').innerHTML = data.hasOwnProperty('name') ?  companyNamed : '';
    localStorage.setItem('companyName', companyNamed);
    return companyNamed;
  });
}