export function encodeDateMinus30DaysToUrl() {
 // Get current date
 let currentDate = new Date();

 // Subtract 30 days
 let pastDate = new Date(currentDate.setDate(currentDate.getDate() - 30));

 // Format date as yyyy/mm/dd
 let year = pastDate.getFullYear();
 let month = ("0" + (pastDate.getMonth() + 1)).slice(-2);
 let day = ("0" + pastDate.getDate()).slice(-2);
 let formattedDate = `${year}/${month}/${day}`;

 // Encode URL
 let encodedUrl = encodeURIComponent(formattedDate);

 return encodedUrl;
}