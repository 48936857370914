import { encodeDateMinus30DaysToUrl } from './30DaysBeforeToday';
import { obterDataFormatada } from './todayDate';

export async function checkDot() {
  const requestOptions = {
    method: 'GET',
    headers: { 
      "accept": "application/json",
      "accept-language": "pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7",
      "authorization": localStorage.getItem('token'),
      "content-type": "application/json",
      "if-none-match": "W/\"1186f-3c2PqL1eocqag01MrbGuekXTmK8\"",
      "sec-ch-ua": "\"Not_A Brand\";v=\"99\", \"Google Chrome\";v=\"109\", \"Chromium\";v=\"109\"",
      "sec-ch-ua-mobile": "?0",
      "sec-ch-ua-platform": "\"Windows\"",
      "sec-fetch-dest": "empty",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "cross-site" 
    }
  };

  const firstDate = encodeDateMinus30DaysToUrl();
  console.log(firstDate);
  const lastDate = obterDataFormatada();
  const userId = localStorage.getItem('userId');
  const url = `https://backend.apexhos.com/hos_events?userId=${userId}&eventTime.logDate.date%5B%24lte%5D=${lastDate}&eventTime.logDate.date%5B%24gte%5D=${firstDate}`;
  console.log(url);
  try {
    const response = await fetch(url, requestOptions);
    const result = await response.json();
    
    result.data.sort((a, b) => {
      const aDate = new Date(a.eventTime.logDate.date);
      const bDate = new Date(b.eventTime.logDate.date);
      return bDate - aDate;
    });
    let i = 0;
    result.data.some((event) => {
      i++;
      if (event.i === true){
        alert(`DOT INSPECTION ON ${event.eventTime.logDate.date}`);
        return true;
      }
      if (i === result.data.length) {
        alert('No DOT INSPECTION in the last 30 days');
      }
      else{
        return false;
      }

    });
  } catch (error) {
    console.log(error);
  }
}