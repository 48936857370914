export function convertTimestampToET(timestamp, timeZone) {
  const date = new Date(timestamp);
  let result = '';
  switch (timeZone) {
    case 'ET': // Eastern Time Zone
      result = 'America/New_York';
      break;
    case 'PT': // Pacific Time Zone
      result = 'America/Los_Angeles';
      break;
    case 'AT': // Alaska Time Zone
      result = 'America/Anchorage';
      break;
    case 'MT': // Mountain Time Zone
      result = 'America/Denver';
      break;
    case 'CT': // Central Time Zone
      result = 'America/Chicago';
      break;
    default:
      result = 'America/New_York';
  }
  const options = { timeZone: result };
  return date.toLocaleString("en-US", options);
}